import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import TempFilters from "../../components/Filters/TempFilters";
import { NavLink } from 'react-router-dom';

const MyLoggedIssues = () => {
    const [showResponse, setShowResponse] = useState(false);
    const [identity, setIdentity] = useState('');
    const [category, setCategory] = useState('');
    const [additionalDetail, setAdditionalDetail] = useState('');
    const [attention, setAttention] = useState('');

    // Handle radio button change
    const handleIdentityChange = (event) => {
        setIdentity(event.target.id);
    };

    // Handle select menu change
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    // Handle textarea change
    const handleAdditionalDetailChange = (event) => {
        setAdditionalDetail(event.target.value);
    };

    // Handle radio button for attention change
    const handleAttentionChange = (event) => {
        setAttention(event.target.id);
    };

    // Handle form submission
    const handleSubmit = () => {
        setShowResponse(true)
    };

    const handleCancel = () => {
        setShowResponse(false)
    };


  return (
    <>
        <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"Issue Logs / My Issue Logs"} />

        <main id="main" className="main">
            <section className="section ">
                <TempFilters />
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='card'>
                        <div className='card-body'>
                            <table className="table  performance-table">
                                <thead>
                                    <tr className='text-center'>
                                        <th scope="col" className=''>Ref #</th>
                                        <th scope="col" className=''>Name</th>
                                        <th scope="col" className=''>last Name</th>
                                        <th scope="col" className=''>Logged Date</th>
                                        <th scope="col" className=''>Status</th>
                                        <th scope="col" className=''>Action</th>
                                        <th scope="col" className=''>Resolved By</th>
                                        <th scope="col" className=''>Resolved Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="">
                                        <td className="tps-2">FL00245C</td>
                                        <td className="tps-2">Joe</td>
                                        <td className="tps-2">Slovo</td>
                                        <td className="tps-2">7 Sep 2023</td>
                                        <td className="tps-2">Investigating</td>
                                        <td className="tps-2 fw-bold fst-italic text-decoration-underline">
                                        <NavLink to='/employee-issues/1' className="text-black">View</NavLink>

                                        </td>
                                        <td className="tps-2"></td>
                                        <td className="tps-2"></td>
                                    </tr>

                                    <tr className="">
                                        <td className="tps-2">ML00132F</td>
                                        <td className="tps-2">Anonymous</td>
                                        <td className="tps-2">Anonymous</td>
                                        <td className="tps-2">11 Sep 2023</td>
                                        <td className="tps-2">
                                            Not Started <i className="fa-solid fa-bell text-warning"></i>
                                        </td>
                                        <td className="tps-2 fw-bold fst-italic text-decoration-underline">
                                        <NavLink to='/employee-issues/2' className="text-black">View</NavLink>

                                        </td>
                                        <td className="tps-2"></td>
                                        <td className="tps-2"></td>
                                    </tr>

                                    <tr className="">
                                        <td className="tps-2">FJ00345C</td>
                                        <td className="tps-2">Sam</td>
                                        <td className="tps-2">Smith</td>
                                        <td className="tps-2">15 Sep 2023</td>
                                        <td className="tps-2">
                                            Not Started <i className="fa-solid fa-bell text-warning"></i>
                                        </td>
                                        <td className="tps-2 fw-bold fst-italic text-decoration-underline">
                                            <NavLink to='/employee-issues/3' className="text-black">View</NavLink>
                                        </td>
                                        <td className="tps-2"></td>
                                        <td className="tps-2"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <spa>
                                    Records 1 to 3 of 3
                                </spa>
                            </div>
                            <div>
                                <i className="fa-solid fa-bell text-warning"></i> Your Action Required
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  )
}
export default MyLoggedIssues;
