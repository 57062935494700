import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const QueryLogging = () => {
    const [showResponse, setShowResponse] = useState(false);
    const [identity, setIdentity] = useState('');
    const [category, setCategory] = useState('');
    const [additionalDetail, setAdditionalDetail] = useState('');
    const [attention, setAttention] = useState('');

    // Handle radio button change
    const handleIdentityChange = (event) => {
        setIdentity(event.target.id);
    };

    // Handle select menu change
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    // Handle textarea change
    const handleAdditionalDetailChange = (event) => {
        setAdditionalDetail(event.target.value);
    };

    // Handle radio button for attention change
    const handleAttentionChange = (event) => {
        setAttention(event.target.id);
    };

    // Handle form submission
    const handleSubmit = () => {
        setShowResponse(true)
    };

    const handleCancel = () => {
        setShowResponse(false)
    };



  return (
    <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"Issue Logs / Log a Query or Issue"} />

      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                    {!showResponse && (
                        <div className="row">
                            <div className="col-md-6">
                                <div className='card-description mx-3 my-3 border p-3 rounded-2'>
                                    <label className="form-check-label" >Please select applicable:</label>
                                    <div className="mb-3">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="identity" id="identity1" />
                                            <label className="form-check-label" htmlFor="identity1">Anonymous</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="identity" id="identity2" />
                                            <label className="form-check-label" htmlFor="identity2">Log issue with my details</label>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="formGroupExampleInput" className="form-label">
                                            Please select a category:
                                        </label>
                                        <select className="form-select form-select mb-3" name="category">
                                            <option selected>Open this select menu</option>
                                            <option value="1">Work related query / issue </option>
                                            <option value="2">Office related query / issue</option>
                                            <option value="4">Personal related query / issue</option>
                                            <option value="5">Inappropriate behaviour</option>
                                            <option value="6">Complaint</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Please provide additional details</label>
                                        <textarea className="form-control" name="additional-detail" id="additional-detail" rows="3"></textarea>
                                    </div>

                                    <label className="form-check-label" >For Attention:</label>
                                    <div className="mb-3">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="attention"  id="line-manager"
                                                   checked={attention === 'line-manager'}
                                                   onChange={handleAttentionChange}/>
                                            <label className="form-check-label" htmlFor="line-manager">Line Manager</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="attention" id="hr"
                                                   checked={attention === 'hr'}
                                                   onChange={handleAttentionChange}/>
                                            <label className="form-check-label" htmlFor="hr">HR Business Partner</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="attention" id="both"
                                                   checked={attention === 'both'}
                                                   onChange={handleAttentionChange}/>
                                            <label className="form-check-label" htmlFor="both">Both</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-danger me-2" name="save" onClick={handleCancel}>Cancel</button>
                                        <button className="btn btn-primary" name="save" onClick={handleSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showResponse && (
                        <div className="row">
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='card-description mx-3 my-3 border p-3 rounded-2'>
                                <span className="h5 mb-3">
                                    Thank you for submitting your query / issue.
                                </span>
                                    <br/><br/>
                                    <p>
                                        An email has been sent to your selected recipient/s. Feedback can be expected within 2 working days, and an email notification will be
                                        sent to you when feedback has been generated.
                                    </p>
                                    <p>
                                        You can access the system at any time to view or track the status of your logged query / issue. Your reference number for this logged
                                        Query / issue is <span className="fw-bold">FJ00345C</span> and has been emailed to you.
                                    </p>

                                    <div className="d-flex flex-column">
                                        <span className="">Kind Regards,</span>
                                        <span className="fw-bold">Online Issue Logging & Resolution</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end me-3">
                                    <button className="btn btn-danger me-0" name="save" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default QueryLogging;
