import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';

const Grow = () => {



  return (
    <>
            <Header icon={"fa-solid fa-users fa-xl mx-2"}  label={"People Management/Grow"} />

      <main id="main" className="main">

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>

                  <div className='card-description'>
                    <p>
                    Our commitment to retaining and cultivating the talent that propels our organisation's success involves proactively managing and nurturing our employees' performance. This proactive approach fosters a dynamic environment that not only support employees' continuous growth and skill development but also enhances job satisfaction.
                    </p>
                  </div>
                  <div className='content-block'>

                    <div className="d-flex align-items-center mb-3">
                          <span className={"icon-shape icon shadow rounded-4 me-2 h-1"}>
                           <i className="fa-solid fa-list-check"></i>
                          </span>
                      <div className='h-1 card-content-headers'>
                        Performance Management
                      </div>
                    </div>
                    <p>
                    Performance Management is a framework by which managers and employees work together to plan, monitor, and review an employee's work objectives and overall contribution to the organisation.
                    </p>
                    <div className='link-container'>
                      <a href='https://demo-performance.concisesolutions.co.za/' target={"_blank"}>
                      Click here to access the Performance Management system
                      </a>
                    </div>
                  </div>

                  <div className='content-block'>
                    <div className="d-flex align-items-center mb-3">
                          <span className={"icon-shape icon shadow rounded-4 me-2 h-1"}>
                           <i className="fa-solid fa-user-group"></i>
                          </span>
                      <div className='h-1 card-content-headers'>
                        Talent Management & Succession
                      </div>
                    </div>
                    <p>
                    Talent Management is a process that aims to identify and put plans in plans for talent development in a structured manner. This process adds substantial value to the business, increase staff retention, mobility, organisation performance and productivity.
                    </p>
                    <div className='link-container'>
                      <a href='https://demo-talent.concisesolutions.co.za/' target={'_blank'}>
                      Click here to access the Talent Management & Succession system
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Grow;
