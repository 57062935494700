import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import { Button, Modal } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
  const [search, setSearch] = useState("")
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {

      } catch (error) {

      }
    };

    fetchData();
  }, []);
  const showModal = () => {
    setShow(true)
  }
  const closeModal = () => {
    setShow(false)
  }


  const [showResponse, setShowResponse] = useState(false);
  const [identity, setIdentity] = useState('');
  const [category, setCategory] = useState('');
  const [additionalDetail, setAdditionalDetail] = useState('');
  const [attention, setAttention] = useState('line-manager');

  // Handle radio button change
  const handleIdentityChange = (event) => {
    setIdentity(event.target.id);
  };

  // Handle select menu change
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  // Handle textarea change
  const handleAdditionalDetailChange = (event) => {
    setAdditionalDetail(event.target.value);
  };

  // Handle radio button for attention change
  const handleAttentionChange = (event) => {
    setAttention(event.target.id);
  };

  // Handle form submission
  const handleSubmit = () => {
    setShowResponse(true)
  };

  const handleCancel = () => {
    setShowResponse(false)
  };

  return (
    <>
      <Header label={<span className='py-0 my-0 h4'>Welcome to the Issue Logging & Resolution System</span>} />
      <main id="main" className="main">
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  {/* <div className='h-4 card-content-headers mb-2'>
                    Welcome to Workwise Portal, your central hub for all things related to Human Resources.
                  </div>*/}

                  <div className='card-description mb-0'>
                    <p>
                      The Issue Logging and Resolution system provides an efficient internal mechanism for addressing employee concerns in a manner
                      perceived as fair, open, transparent, and supported by clear communication and tangible results. This system establishes a formalised
                      tracking solution, ensuring that managers and HR are mutually accountable for addressing every employee concern in accordance with
                      our organisation’s policies and approach.
                    </p>
                    <p>
                      <a href="#" className="text-primary fw-bold fst-italic">Click here</a> to view the Issue Policy document
                    </p>

                    <div className="bg-aqua mx-3 px-3 py-4 rounded-2 d-flex align-items-center mb-3">
                      <span>
                        To log a query / issue, please  <span onClick={()=>{navigate("/log-query")}} className="pointer text-primary fw-bold fst-italic">click here</span>
                      </span>

                    </div>

                    <div className="bg-aqua mx-3 px-3 py-4 rounded-2 d-flex align-items-center mb-3 justify-content-between">
                      <span>
                        To view the status of your logged issue, please enter the issued reference number:
                      </span>
                      <div className="d-flex flex-row align-items-end">
                        <input onChange={(e)=>setSearch(e.target.value)} value={search} type="text" className="form-control-sm" />
                        <button onClick={() => {search =="FJ00345C" && showModal() }} className="btn btn-sm btn-primary ms-2">Enter</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>

                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                  </div>

                  <div className='row d-flex'>
                    <div className='col-lg-7 mx-auto mt-4'>
                      <img className='fluid-image rounded ' src='assets/images/issue-log-flow.png' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <Modal show={show} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            Issue Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body'>
            <div className=""><h4 className='text-indigo-blue ms-3'></h4></div>
            <div className="row">
              <div className="col-md-12">
                <div className='mx-3 my-3 border p-1 rounded-2'>

                  <div className="row">
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='card-description mx-3 my-3'>
                        <ul className="list-group">
                          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                            <div className="d-flex flex-row">
                              <div className="query-item-label">Reference Number:</div><strong className="text-dark">FJ00345C</strong>
                            </div>
                          </li>

                          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                            <div className="d-flex flex-row">
                              <div className="query-item-label">Issue Logged by:</div><strong className="text-dark">Sam Smith</strong>
                            </div>
                          </li>

                          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                            <div className="d-flex flex-row">
                              <div className="query-item-label">Logged Date:</div><strong className="text-dark">15 Sep 2023</strong>
                            </div>
                          </li>

                          <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                            <div className="d-flex flex-row">
                              <div className="query-item-label">Category:</div><strong className="text-dark">Office related query / issue</strong>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className="mb-3 mx-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Additional detail:</label>
                        <textarea className="form-control" name="additional-detail" id="additional-detail" rows="2" disabled={true}
                          value={"Is there way in which the aircons can be adjusted next to my desk? \n" +
                            "I’m constantly getting sick from the very cold air. Thanks."}>

                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <hr className="hr-dashed" />

            <div className="row">
              <div className="col-md-12">
                <div className='card-description mx-3 my-3 border p-3 rounded-2'>
                  <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                      <div className="d-flex flex-row">
                        <div className="query-item-label">Current Status:</div><strong className="text-dark">Not Started</strong>
                      </div>
                    </li>
                  </ul>

                  <div className="mb-3 mt-2">
                    <label htmlFor="formGroupExampleInput" className="form-label">
                      Please select a category:
                    </label>
                    <select className="form-select form-select mb-3" name="category">
                      <option selected>Open this select menu</option>
                      <option value="1">Not Stated</option>
                      <option value="2">In Progress</option>
                      <option value="3" selected>Investigating</option>
                      <option value="4">Resolved / Closed</option>
                      <option value="5">Cannot Resolve</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">My Comments / Feedback</label>
                    <textarea className="form-control" name="additional-detail" id="additional-detail" rows="3"></textarea>
                  </div>

                  <label className="form-label">
                    Re-route query / issue?
                  </label>
                  <div className="mb-3 ms-3">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="query-routing" id="no"
                        checked={attention === 'no'}
                        onChange={handleAttentionChange} />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="query-routing" id="line-manager"
                        checked={attention === 'line-manager'}
                        onChange={handleAttentionChange} />
                      <label className="form-check-label me-2" htmlFor="line-manager">
                        Line Manger
                      </label>
                    </div>
                    {attention === 'line-manager' && (
                      <div className="form-check mb-2">
                        <input type="text" className="me-2" name="line-manager-text" disabled={true} value="Albert Young" />
                        <a href="" className="fw-bold fst-italic text-dark">
                          Click here to select different manager.
                        </a>
                      </div>
                    )}

                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="query-routing" id="hr"
                        checked={attention === 'hr'}
                        onChange={handleAttentionChange} />
                      <label className="form-check-label" htmlFor="hr">
                        Different HR Business Partner
                      </label>
                    </div>
                    {attention === 'hr' && (
                      <div className="form-check mb-2">
                        <input type="text" className="me-2" name="hr-text" disabled={true} />
                        <a href="" className="fw-bold fst-italic text-dark">
                          Click here to select different HR personnel
                        </a>
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-end">
                    <button className="btn btn-danger me-2" name="save" onClick={handleCancel}>Cancel</button>
                    <button className="btn btn-primary" name="save" onClick={handleSubmit}>Update</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Dashboard;
